// $('.owl-carousel').owlCarousel({
//     items: 5,
//     loop: true,
//     margin: 20,
//     nav: true,
//     dots: false,
//     navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],

//     responsive:{
//         0: {
//             items:1
//         },
//         700: {
//             items:3
//         },
//         600: {
//             items:2
//         },
//         1000: {
//             items:5
//         }
//     }
// })

// $(document).ready(function () {
//     // $('.main-header-container .dropdown').hover(function () {
//     //     $(this).addClass('show');
//     //     $(this).find('.dropdown-menu').addClass('show');
//     // }, function () {
//     //     $(this).removeClass('show');
//     //     $(this).find('.dropdown-menu').removeClass('show');
//     // });

//     //read-more button functionality
//     $('#read_more').on('click',function(){
//         var val=$(this).text();
//         if(val=="Read More"){
//             $(this).text('Read Less');
//         }
//         else{
//             $(this).text('Read More');
//         }
//         $('.read-more-content').toggle(500);
//     })
// });

// $( ".owl-prev").html('<i class="fa fa-chevron-left"></i>');
// $( ".owl-next").html('<i class="fa fa-chevron-right"></i>');

// Screen Reveal Effects
$(document).ready(function () {
    window.sr = ScrollReveal({
        origin: "bottom",
        distance: "80px",
        duration: 2000,
        reset: false,
    });

    // Home page reveals
    sr.reveal(".fadeLeft", { origin: "left", interval: 200, delay: 400 });
    sr.reveal(".fadeRight", { origin: "right", interval: 200, delay: 400 });
});

$(document).ready(function () {
    window.sr = ScrollReveal({
        distance: "80px",
        duration: 2000,
        reset: false,
    });

    sr.reveal(".fadeUp", { interval: 200, delay: 400 });
});

//Tap to Top js
$(document).ready(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('.back-to-top').fadeIn();
        } else {
            $('.back-to-top').fadeOut();
        }
    });
    // scroll body to 0px on click
    $('.back-to-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
        return false;
    });
});


// $(document).ready(function () {
//     $('.read-more-btn').click(function () {
//         $(".pooja-content").toggleClass("expand");
//     });
// });